const getters = {
  partner_purchase: ({ partnerPurchase }) => {
    return partnerPurchase || { claimed: true }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
