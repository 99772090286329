import { db } from '../../../../plugins/firebase'

const getters = {
  will_executors: ({ data }, getters) => {
    const executors = data?.executors || {}
    const value = {
      primary: executors.primary || [],
      backup: executors.backup || [],
    }

    value.primary = value.primary.map((v) => {
      if (typeof v === 'string') {
        return v
      }
      if (v?.professional) {
        return 'ausUnity'
      }
    })

    value.backup = value.backup
      .map((v) => {
        if (typeof v === 'string') {
          return v
        }
        if (v?.professional) {
          return 'ausUnity'
        }
      })
      .filter((v) => {
        return !value.primary.includes(v)
      })

    let addressesProvided = value.primary.concat(value.backup).every((id) => {
      if (id === 'ausUnity') {
        return true
      }
      let person = getters.entity(id)
      return person?.address?.trim().length
    })

    return {
      value: value,
      isComplete: value.primary.length > 0 && addressesProvided,
      isProfessionalWilled:
        executors.type === 'professional' && value.primary.length === 0,
    }
  },
}

const actions = {
  async save_professional_executor_acceptance(
    { getters },
    { name, company = 'ausUnity' }
  ) {
    const { uid, email } = getters.auth_user

    const data = {
      uid,
      emailedTo: email,
      company,
      acceptedBy: name || '',
      acceptedAt: new Date(),
      onCreate: ['sendEmail'],
    }

    return await db.collection('professionalExecutorAgreements').add(data)
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
